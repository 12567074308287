@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf'); /* IE9 Compat Modes */
  src: local('SF Pro Display'), local('SF Pro Display'),
       url('./assets/fonts/SF-Pro-Display-Regular.otf') format('opentype'), /* IE6-IE8 */
}

body {
  font-family: 'SF Pro Display';
}
.App {
  text-align: center;
  font-family: 'SF Pro Display';
  max-width: 500px;
  margin: 0 auto;
}

.App-logo {
  height: 55vmin;
  width: 100%;
  background-image: url("./assets/BG-BANNER.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
}

.car-banner {
  height: 50vmin;
  width: 80vmin;
  background-image: url("./assets/PG2-BG-BANNER.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.banner-title{
  color: #FFF;
  font-family: 'SF Pro Display';
  margin-left: 1rem;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 500;;
}

.banner-title strong {
  font-size: 1.75rem;
}

.App-header {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #0d2355fd;
}

.App-link {
  color: #61dafb;
}

.form-phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1.5rem;
  margin-top: 0.5rem;;
}

.form-phone .form-group {
  margin-bottom: 0.5rem ;
}

.phone-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0;
  padding-left: 10px;
  padding-top: .35rem;
  padding-bottom: .35rem;
  border: 2px solid #ced4da;
}

.phone-input-container h6 {
  margin-bottom: 0;
  font-size: 20px;
}
.phone-input-container .phone-input {
  border-width: 0;
  padding: 0;
  padding-left: 0.375rem;
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

.phone-input-container .phone-input:focus {
  color: #000;
  background-color: #fff;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
.form-input {
  border-radius: 0;
  padding: 1.5rem;
  border: 2px solid #ced4da;
  font-weight: 500;
  font-size: calc(10px + 2vmin);
  position: relative;
  display: block;
}

.submit-button {
  border-radius: 0;
  background-color: #0d2355fd;
  color: #FFF;
  padding: 0.75rem;
  font-size: 1.2rem;
  font-weight: 900;
  margin-bottom: .75rem;
}

.submit-button:disabled {
  background-color: #0d2355fd;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1.2rem;
}

.about-header{
  padding: 0 .38rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.about-header--title{
  -webkit-box-flex: 2;
-ms-flex: 2;
flex: 2;
font-size: 1.15rem;
text-align: center;
font-weight: 600;
}
.about-header--left{
  flex: 1;
  height: .1rem;
  background-color: #0d2355ff;
}
.about-header--right{
  flex: 1;
  height: .1rem;
  background-color: #0d2355ff;
}

.about-list {
  display: flex;
  justify-content: space-evenly;
  align-self: center;
  margin-top: 0.5rem;
}

.about-list-item {
  border-width: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: 'SF Pro Display';
  font-size: 1rem;
  margin: 0;
  padding: 0;
  line-height: 2.25rem;
  color: #999;
}

.about-list-item img {
  margin-right: 0.5rem
}

.termos-button {
  color: #0d2355ff;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-self: center;
}